import { Layout } from "../components/Layout";
import Spells from "../components/dnd-character/Spells";

export function DndSpellsPage() {
  return (
    <Layout>
      <Spells />
    </Layout>
  );
}
