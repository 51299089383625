import DndCharacter from "../components/dnd-character/DndCharacter";
import { Layout } from "../components/Layout";

export function DndCharacterPage() {
  return (
    <Layout>
      <DndCharacter />
    </Layout>
  );
}
